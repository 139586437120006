import { BrowserRouter as Router, Switch, Route, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './componentes/navbar';
import Usuarios from "./paginas/usuarios";
import Productos from "./paginas/productos";
import {AppRutas} from "./Rutas";

function App() {
  return (
      <BrowserRouter>
        <AppRutas/>
      </BrowserRouter>
  );
}

export default App;
