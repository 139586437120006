import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(usuario, fecha, descripcion) {
    return { usuario,fecha, descripcion };
}

const rows = [
    createData('eleal', "19 May, 2021 : 10:10 AM", "Registro al usuario jcastro"),
    createData('eleal', "18 May, 2021 : 3:12 PM", "Modifico al producto Silla por silla metalica"),
    createData('eleal', "17 May, 2021 : 2:15 PM", "Modifico la contraseña del usuario eleal"),
    createData('jtorres', "23 Abr, 2021 : 1:15 PM", "Registró al usuario eleal "),
];

export default function CustomizedTables() {
    return (
        <Container>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Usuario</StyledTableCell>
                            <StyledTableCell>Fecha de registro</StyledTableCell>
                            <StyledTableCell>Acción</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">{row.usuario}</StyledTableCell>
                                <StyledTableCell>{row.fecha}</StyledTableCell>
                                <StyledTableCell >{row.descripcion}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}